import React from "react";

export function LinkedInIcon({
  color,
}: {
  color?: string;
}): React.ReactElement {
  const fill = color || "black";
  return (
    <svg
      fill={fill}
      height="30px"
      width="30px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 455 455"
      xmlSpace="preserve"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M246.4,204.35v-0.665c-0.136,0.223-0.324,0.446-0.442,0.665H246.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0,0v455h455V0H0z M141.522,378.002H74.016V174.906h67.506V378.002z
       M107.769,147.186h-0.446C84.678,147.186,70,131.585,70,112.085c0-19.928,15.107-35.087,38.211-35.087
      c23.109,0,37.31,15.159,37.752,35.087C145.963,131.585,131.32,147.186,107.769,147.186z M385,378.002h-67.524V269.345
      c0-27.291-9.756-45.92-34.195-45.92c-18.664,0-29.755,12.543-34.641,24.693c-1.776,4.34-2.24,10.373-2.24,16.459v113.426h-67.537
      c0,0,0.905-184.043,0-203.096H246.4v28.779c8.973-13.807,24.986-33.547,60.856-33.547c44.437,0,77.744,29.02,77.744,91.398V378.002
      z"
        />
      </g>
    </svg>
  );
}
